(<template>
  <div :class="{'in-progress': progressActive,
                'one-assignment--full-width': $isGodmode(),
                'one-assignment--blocked': showBlockedByAvailabilityBlock}"
       class="one-assignment is-progress-bar">
    <printed-info />
    <action-buttons :config="actionButtonsConfig"
                    class="one-assignment__action-buttons" />
    <assignment-breadcrumbs />
    <div class="one-assignment__main-wrapper">
      <chat v-if="showMobileChat" />
      <section v-show="!showMobileChat"
               class="one-assignment__info-cont one-assignment__info-cont--right">
        <info :show-edit-button="actionButtonsConfig.showEditBtn"
              :progress="assignmentInfoProgress"
              class="one-assignment__info"
              @fetchsuitableinterpreters="fetchSuitableInterpreters" />
      </section>
      <div v-show="!showMobileChat"
           class="one-assignment__interactive-area-wrapper">
        <div class="one-assignment__interactive-area">

          <!-- JOB SHORTCUTS FOR MULTIPLE BOOKING -->
          <job-shortcuts v-if="isSeriesJob" />

          <!-- FINISHED BLOCK -->
          <template v-if="showFinishedBlock">
            <finished-job v-if="finishedJobBlockInfo.title"
                          :icon="finishedJobBlockInfo.icon"
                          :type="finishedJobBlockInfo.type"
                          :title="finishedJobBlockInfo.title"
                          :description="finishedJobBlockInfo.description"
                          class="one-assignment__inner-section" />
            <receipt-block v-if="userIsInterpreter || $isGodmode()"
                           class="one-assignment__inner-section" />
          </template>

          <!-- BLOCKS UNDER JOB TITLE -->
          <job-title class="one-assignment__inner-section">
            <template v-if="isCancelledJob">
              <cancel-receipt-block v-if="userIsInterpreter || $isGodmode()" />
            </template>

            <!-- BUYER COMPONENTS -->
            <buyer-new-job-text v-if="isNewAssignment" />
            <buyer-time-passed v-if="isTimePassedJob" />

            <!-- INTERPRETER COMPONENTS -->
            <template v-if="!showBlockedByAvailabilityBlock">
              <price-tmp-apply v-if="showRegularApply" />
              <price-tmp-applied v-if="showRegularApplied" />
              <price-tmp-travel-cost-apply v-if="showTravelCostApply || showTravelRates" />
              <price-tmp-travel-cost-applied v-if="showTravelCostApplied" />
              <int-rejected v-if="isRejectedState" />
              <!--          ToDo: Add shared action-buttons like 'awarded-buttons' -->
              <!--          <apply-buttons/>-->
              <!--          <applied-buttons />-->
              <!--          <awarded-buttons />-->
              <!--          <feedback-buttons />-->
              <!--          <finished-buttons />-->
              <pt-view-log-mobile-btn v-if="isMobInteractionBlockOpened" />
            </template>
            <awarded-block v-if="showAwardedBlock" />
            <blocked-by-availability v-if="showBlockedByAvailabilityBlock" />
          </job-title>

          <!-- FEEDBACK BLOCK -->
          <template v-if="showFeedbackBlock">
            <pt-feedback-invoice-draft v-if="userIsInterpreter"
                                       class="one-assignment__inner-section" />
            <pt-feedback class="one-assignment__inner-section" />
          </template>

          <!-- FAI BLOCK -->
          <fai v-if="showFaiBlock"
               ref="fai"
               class="one-assignment__inner-section" />
          <accordion v-if="previouslyConfirmedInterpreters"
                     class="one-assignment__replacement-accordion">
            <h2 slot="header"
                class="default-accordion__header">{{ $gettext('Previously confirmed interpreters') }}</h2>
            <div slot="content"
                 class="default-accordion__content">
              <div>
                <interpreters-list :interpreters="previouslyConfirmedInterpreters"
                                   class="dashboard__interpreters-list" />
              </div>
            </div>
          </accordion>
          <!-- CHAT COMPONENTS -->
          <accordion v-if="showChatAccordion"
                     :initial-opened-state="isAppliedJob && userIsInterpreter"
                     class="one-assignment__chat-accordion">
            <h2 slot="header"
                class="default-accordion__header">{{ $gettext('Chat area') }}</h2>
            <div slot="content"
                 :class="{'one-assignment__chat-wrapper--thin': isThinChat}"
                 class="default-accordion__content one-assignment__chat-content">
              <template v-if="showChatSection">
                <discussions v-if="userIsNotInterpreter" />
                <chat v-if="showJobChat" />
              </template>
            </div>
          </accordion>
          <no-chat-info v-else-if="showNoChatSection" />
        </div>
        <div v-if="(showFinishedBlock || isCancelledJob) && $isGodmode() && jobStatementId"
             class="one-assignment__confirmation-area">
          <decision-admin-tools class="one-assignment__inner-section" />
        </div>
      </div>
      <div v-if="$isGodmode()"
           class="one-assignment__info-cont one-assignment__info-cont--left one-assignment__info-cont--dark">
        <!-- ADMIN CHAT COMPONENTS -->
        <accordion v-if="$isGodmode()"
                   :initial-opened-state="true"
                   class="one-assignment__inner-section">
          <h2 slot="header"
              class="default-accordion__header">{{ $gettext('Internal assignment chat') }}</h2>
          <admin-chat slot="content"
                      class="default-accordion__content one-assignment__admin-chat-content" />

        </accordion>

        <info :show-edit-button="actionButtonsConfig.showEditBtn"
              :progress="assignmentInfoProgress"
              :show-admin-section-only="true"
              class="one-assignment__info"
              @fetchsuitableinterpreters="fetchSuitableInterpreters" />
      </div>
      <magic-links-handler v-if="jobStatus" />
      <printed-footer />
    </div>
  </div>
</template>)

<script>
  import {mapGetters, mapState} from 'vuex';
  import helpers from '@/helpers';
  import Info from '@/components/assignment_components/one_assignment/assignment_info/AssignmentInfo';
  import Discussions from '@/components/assignment_components/one_assignment/chat/ChatInterpretersList';
  import JobChat from '@/components/assignment_components/one_assignment/chat/JobChat';
  import ApplyPrototype from '@/prototypes/one_assignment_page/ApplyPrototype';
  import Accordion from '@/components/shared_components/Accordion';
  import InterpretersList from '@/components/interpreters_components/InterpreterForReplacement';

  export default {
    asyncData({store, route}) {
      const jobID = route.params.id;
      let promisesArr = '';
      if (!store.state.OneAssignmentStore.chatDiscussions) {
        promisesArr = [...promisesArr, store.dispatch('OneAssignmentStore/getDiscussions', jobID)];
      }

      if (store.state.OneAssignmentStore.storeJobObj
        && ['accepted', 'in_progress', 'needsFeedback', 'finished'].includes(store.state.OneAssignmentStore.storeJobObj.status)
        && !store.state.OneAssignmentStore.jobCallDetails) {
        promisesArr = [...promisesArr, store.dispatch('OneAssignmentStore/getCallDetails', jobID)];
      }
      const storeJobObj = store.state.OneAssignmentStore.storeJobObj;
      const checkInPersonSessionForFinished = (storeJobObj.status == 'finished')
        ? (storeJobObj.interpretationRequirement.sessionType === 'in_person') : true;
      if (store.getters['UserInfoStore/userIsInterpreter']
        && store.state.OneAssignmentStore.storeJobObj
        && !store.state.OneAssignmentStore.storeJobObj.locked
        && store.state.OneAssignmentStore.storeJobObj.usesPricingTemplate
        && !store.state.AssignmentFlowStore.previewData.length
        && !['cancelled', 'closed', 'rejected', 'unsuitable']
          .includes(store.state.OneAssignmentStore.storeJobObj.status)
        && checkInPersonSessionForFinished) {
        promisesArr = [...promisesArr, store.dispatch('AssignmentFlowStore/getInterInvoicePreviewData', {
          jobId: jobID
        })];
      }
      if (store.state.OneAssignmentStore.storeJobObj.bookingMechanism === 'price_check'
        && store.getters['UserInfoStore/userIsNotInterpreter']
        && !store.state.OneAssignmentStore.storeJobObj.locked
        && !['cancelled', 'closed', 'finished', 'rejected', 'unsuitable']
          .includes(store.state.OneAssignmentStore.storeJobObj.status)
        && !store.state.AssignmentFlowStore.pricingData.length) {
        promisesArr = [...promisesArr, store.dispatch('AssignmentFlowStore/getBuyerInvoicePreview', {
          jobId: jobID
        })];
      }
      if (store.state.OneAssignmentStore.storeJobObj
        && store.state.OneAssignmentStore.storeJobObj.locked
        && store.state.OneAssignmentStore.storeJobObj.usesPricingTemplate
        && ['finished', 'cancelled']
          .includes(store.state.OneAssignmentStore.storeJobObj.status)) {
        if (store.getters['UserInfoStore/userIsInterpreter'] || store.state.TTAuthStore.godmode) {
          const jobStatus = store.state.OneAssignmentStore.storeJobObj.status;
          promisesArr = [...promisesArr, store.dispatch('AssignmentFlowStore/getFinalInvoice', {jobId: jobID, jobStatus})];
        }
      }
      if (store.state.OneAssignmentStore.storeJobObj
        && ['needsFeedback', 'finished'].includes(store.state.OneAssignmentStore.storeJobObj.status)) {
        promisesArr = [...promisesArr, store.dispatch('FeedbackStore/getLastUpdatedData', {jobId: jobID})];
        if (store.getters['UserInfoStore/userIsEnterpriseMember'] && !store.state.OneAssignmentStore.storeJobObj.locked) {
          const bookerUid = store.state.OneAssignmentStore.storeJobObj.interpretationRequirement?.bookerUid || '';
          const departmentId = store.state.OneAssignmentStore.storeJobObj.interpretationRequirement?.department?.id || '';

          promisesArr = [...promisesArr, store.dispatch('FeedbackStore/getInvoiceSettingsForParticularBooker', {bookerUid, departmentId})];
        }
      }
      if (!store.state.TTSignaturesStore.documentsForSigning && store.getters['UserInfoStore/userIsInterpreter']) {
        promisesArr = [...promisesArr, store.dispatch('TTSignaturesStore/getDocumentsForSigning')];
      }
      if (!store.state.s_DashboardStore.feedbackJobs && store.getters['UserInfoStore/userIsInterpreter']) {
        promisesArr = [...promisesArr, store.dispatch('s_DashboardStore/getFeedbackJobs')];
      }
      // Filling the selectors for pricing templates
      if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.demanderTemplates).length === 0) {
        promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'demander')];
      }
      if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.supplierTemplates).length === 0) {
        promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'supplier')];
      }
      return promisesArr ? Promise.all(promisesArr) : promisesArr;
    },
    metaInfo() {
      const storeJobObj = this.$store.state.OneAssignmentStore.storeJobObj;
      const jobId = storeJobObj ? helpers.getJobInfo.jobId(storeJobObj) : this.$route.params.id;

      return {title: `#${jobId} - ${this.$getString('metaData', 'longTitle')}`};
    },
    components: {
      accordion: Accordion,
      'interpreters-list': InterpretersList,
      info: Info,
      'action-buttons': () => import('@/components/assignment_components/one_assignment/assignment_info/AssignmentActionButtons'),
      'assignment-breadcrumbs': () => import('@/components/assignment_components/one_assignment/AssignmentBreadcrumbs'),
      'admin-chat': () => import('@/components/admin_chat/AdminChatIframe'),
      'magic-links-handler': () => import('@/components/assignment_components/one_assignment/MagicLinkHandler'),
      'job-shortcuts': () => import('@/components/assignment_components/one_assignment/interactive_info/JobShortcutsWrapper'),
      'job-title': () => import('@/components/assignment_components/one_assignment/interactive_info/JobTitle'),
      // CHAT SECTIONS
      chat: JobChat,
      discussions: Discussions,
      'no-chat-info': () => import('@/components/assignment_components/one_assignment/no_chat_info/NoChatInformation'),
      // BUYER TOP SECTIONS
      fai: () => import('@/components/assignment_components/one_assignment/interactive_info/invite_suppliers/FindInterpreterBlock'),
      'buyer-new-job-text': () => import('@/components/assignment_components/one_assignment/interactive_info/BuyerNewJobTextInfo'),
      'buyer-time-passed': () => import('@/components/assignment_components/one_assignment/interactive_info/BuyerTimePassedInfo'),
      // INTERPRETER PRICE TEMPLATE APPLY SECTIONS
      'price-tmp-apply': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_applying/PtApply'),
      'price-tmp-applied': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_applying/PtApplied'),
      'price-tmp-travel-cost-apply': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_applying/travel_costs_apply/PtTravelCostApply'),
      'price-tmp-travel-cost-applied': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_applying/travel_costs_apply/PtTravelCostApplied'),
      // // AWARDED JOB SECTIONS
      'awarded-block': () => import('@/components/assignment_components/one_assignment/awarded_assignment/shared_components/AwardedBlock'),
      // FEEDBACK SECTIONS
      'pt-feedback': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_feedback/AssignmentFeedback'),
      'pt-feedback-invoice-draft': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_feedback/FeedbackInvoiceDraft'),
      // FINISHED BLOCKED SECTIONS
      'decision-admin-tools': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_finished/admin_tools/DecisionAdminTools'),
      'blocked-by-availability': () => import('@/components/assignment_components/one_assignment/assignment_info/BlockedByAvailabilitySection'),
      'int-rejected': () => import('@/components/assignment_components/one_assignment/interactive_info/RejectedAssignment'),
      'finished-job': () => import('@/components/assignment_components/one_assignment/interactive_info/FinishedAssignment'),
      'receipt-block': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_finished/ReceiptBlock'),
      // CANCELLED SECTIONS
      'cancel-receipt-block': () => import('@/components/assignment_components/one_assignment/interactive_info/pt_cancelled/CancelReceiptBlock'),
      // PRINT COMPONENTS
      'printed-info': () => import('@/components/assignment_components/one_assignment/AssignmentPrintInfo'),
      'printed-footer': () => import('@/components/assignment_components/one_assignment/AssignmentPrintFooter')
    },
    extends: ApplyPrototype,
    data() {
      return {
        assignmentInfoProgress: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userIsNotInterpreter',
        'userIsInterpreter',
        'userCanUseChat'
      ]),
      ...mapState('AssignmentFlowStore', ['invoiceGenerationInProgress']),
      ...mapGetters('OneAssignmentStore', [
        'isSeriesJob',
        'jobFeedback'
      ]),
      ...mapGetters('JobDecisionStore', [
        'jobDemanderInfo',
        'jobSupplierInfo',
        'statement'
      ]),
      ...mapState('OneAssignmentStore', {
        activeMobileChat: (state) => state.mobileChatOpened,
        progressActive: (state) => state.progressActive,
        mobileInteractionContentOpened: (state) => state.mobileInteractionContentOpened,
        assignmentObj: (state) => state.storeJobObj || {},
        interDiscussion: ({chatDiscussions}) => chatDiscussions?.discussion || {}
      }),
      bookingMechanism() {
        return this.assignmentObj.bookingMechanism;
      },
      interDiscussionStatus() { return this.interDiscussion.status || ''; },
      jobStatus() { return this.assignmentObj.status || ''; },
      assignmentRequirements() { return this.assignmentObj.interpretationRequirement || {}; },
      jobType() { return this.assignmentRequirements.jobType || ''; },
      jobStatementId() { return this.assignmentObj.statementId || ''; },
      statement() { return this.assignmentObj.statement || {}; },
      // JOB STATUSES
      isUnsuitableJob() { return this.jobStatus === 'unsuitable'; },
      isCancelledJob() { return this.jobStatus === 'cancelled'; },
      isFinishedJob() { return this.jobStatus === 'finished'; },
      isAcceptedJob() { return this.jobStatus === 'accepted'; },
      isInProgressJob() { return this.jobStatus === 'in_progress'; },
      isPublishedJob() { return this.jobStatus === 'published'; },
      isDraftJob() { return this.jobStatus === 'draft'; },
      isAppliedJob() { return this.jobStatus === 'applied'; },
      isClosedJob() { return this.jobStatus === 'closed'; },
      isRejectedJob() { return this.jobStatus === 'rejected'; },
      isFeedbackJob() { return this.jobStatus === 'needsFeedback'; },
      isProactiveJob() { return this.jobStatus === 'proactive'; },
      // DISCUSSIONS STATUSES
      isRejectedDiscussion() { return this.interDiscussionStatus === 'rejected'; },
      // JOB ACCESSIBILITY
      isPrivateJob() { return this.assignmentObj.accessibility === 'private'; },
      isPublicJob() { return this.assignmentObj.accessibility === 'public'; },
      // OTHER
      showedUp() { return this.jobFeedback.showedUp === true; },
      isBlockedByAvailabilityJob() { return this.interDiscussion.blockedByAvailability; },
      previouslyConfirmedInterpreters() { return this.assignmentObj.previouslyConfirmedInterpreters; },
      showBlockedByAvailabilityBlock() {
        return this.isBlockedByAvailabilityJob
          && !this.isCancelledJob
          && !this.isInProgressJob
          && !this.isAcceptedJob
          && !this.isFinishedJob
          && !this.isFeedbackJob;
      },
      userCanSeeChat() { return this.userIsNotInterpreter && !this.$isGodmode() ? this.userCanUseChat : !this.showSelfSummaryBlock; },
      showNoChatSection() { return !this.userCanSeeChat && this.userIsNotInterpreter; },
      showChatSection() { return this.userCanSeeChat && !this.showBlockedByAvailabilityBlock && !this.isProactiveJob; },
      showJobChat() {
        if (this.userIsNotInterpreter) return this.clientWidth > 1024 || this.activeMobileChat;
        return this.clientWidth > 767 || this.activeMobileChat;
      },
      showMobileChat() {
        if (this.userIsNotInterpreter) return this.clientWidth < 1025 && this.activeMobileChat;
        return this.clientWidth < 768 && this.activeMobileChat;
      },
      showChatAccordion() {
        return this.showChatSection
          && (this.userIsNotInterpreter
            || this.showJobChat);
      },
      clientWidth() { return this.$store.state.GlobalStore.clientWidth; },
      isThinChat() {
        return this.isInProgressJob
          || this.isAcceptedJob
          || this.isFinishedJob
          || this.isFeedbackJob;
      },
      isMobInteractionBlockOpened() { return this.userIsInterpreter && this.mobileInteractionContentOpened && this.clientWidth < 768; },
      finishTimePassed() { return this.$moment().isAfter(this.assignmentRequirements.finishTime); },
      jobLocked() { return this.assignmentObj.locked || false; },
      showFeedbackBlock() {
        if (this.userIsInterpreter) {
          return this.isFeedbackJob
            || (this.isFinishedJob && !this.jobLocked) || (this.isCancelledJob && this.assignmentRequirements.sessionType === 'in_person' && !this.jobLocked && this.assignmentObj.feedbackUpdateAllowed);
        } else {
          return this.isFeedbackJob
            || (this.isFinishedJob);
        }
      },
      showFinishedBlock() {
        return this.isFinishedJob
          && this.jobLocked;
      },
      isNewAssignment() {
        return this.userIsNotInterpreter
          && !this.finishTimePassed
          && !this.isAcceptedJob
          && !this.isFeedbackJob
          && !this.isCancelledJob
          && !this.isFinishedJob
          && !this.isClosedJob
          && this.isPublicJob;
      },
      isTimePassedJob() {
        return this.userIsNotInterpreter
          && !this.isAcceptedJob
          && !this.isInProgressJob
          && !this.isFeedbackJob
          && !this.isCancelledJob
          && !this.isFinishedJob
          && this.finishTimePassed;
      },
      isNotStartedJob() {
        return !this.isCancelledJob
          && !this.isInProgressJob
          && !this.isClosedJob
          && !this.isFinishedJob
          && !this.isFeedbackJob;
      },
      isRejectedState() {
        return this.userIsInterpreter
          && !this.isUnsuitableJob
          && (this.isRejectedDiscussion
            || this.isRejectedJob);
      },
      showFaiBlock() {
        return this.userIsNotInterpreter
          && ((this.$isGodmode() && !this.isClosedJob && !this.isCancelledJob && !this.isDraftJob)
            || ((this.isPublishedJob || this.isAppliedJob)
              && this.isPrivateJob
              && !this.finishTimePassed));
      },
      showAwardedBlock() {
        const showAwardedBlock = this.isAcceptedJob || this.isInProgressJob;
        const showAwardedBlockForFinishedJob = this.userIsNotInterpreter && (this.isFinishedJob || this.isFeedbackJob);

        return showAwardedBlock || showAwardedBlockForFinishedJob;
      },
      actionButtonsConfig() {
        const showEditBtn = this.userIsNotInterpreter && (!this.assignmentObj.locked || this.$isGodmode());
        const showCancelBtn = this.isNotStartedJob || (this.$isGodmode() && !this.isCancelledJob);
        const showReplacementBtn = this.$isGodmode()
          && this.userIsNotInterpreter
          && (this.isAcceptedJob || this.isInProgressJob);
        const showCopyBtn = this.userIsNotInterpreter;

        return {
          showEditBtn,
          showCancelBtn,
          showReplacementBtn,
          showCopyBtn
        };
      },
      customerHasDiscount() {
        return this.statement.demanderInvoiceDiscountPercentage > 0;
      },
      showSuccessfulJobBlock() {
        return this.userIsNotInterpreter && this.statement.chargeable && !this.customerHasDiscount;
      },
      showDiscountCustomerBlock() {
        return this.userIsNotInterpreter && this.customerHasDiscount;
      },
      showNoShowBlock() {
        if (this.jobLocked) {
          if (this.userIsNotInterpreter) return !this.statement.chargeable && !this.customerHasDiscount;
          else if (this.userIsInterpreter) return !this.statement.payable;
        }
      },
      finishedJobBlockInfo() {
        //* **** Successful Job ******//
        if (this.showSuccessfulJobBlock) {
          const template = this.$gettext('Thank you for choosing %{domain}!');
          return {
            icon: 'tick',
            type: 'success',
            title: this.$gettextInterpolate(template, {domain: this.$getString('domainData', 'platformName')}),
            description: this.$gettext('This assignment has been locked and is now being handled by our finance team.')
          };
          //* **** Job Requires Invoice Discount ******//
        } else if (this.showDiscountCustomerBlock) {
          return {
            icon: 'info',
            type: 'information',
            title: this.$gettext('We apologize for the inconvenience.'),
            description: this.$gettext('We will reduce the invoice for this assignment.')
          };
          //* **** Not Successful/Chargeable Job ******//
        } else if (this.showNoShowBlock) {
          if (this.userIsInterpreter) {
            return {
              icon: 'info',
              type: 'information',
              title: this.$gettext('You will not be paid for this assignment.'),
              description: ''
            };
          } else {
            return {
              icon: 'info',
              type: 'information',
              title: this.$gettext('We apologize for the inconvenience.'),
              description: this.$gettext('We will not invoice you for this assignment.')
            };
          }
        } else return {};
      }
    },
    watch: {
      bookingMechanism() {
        if (this.bookingMechanism === 'price_check' && this.userIsNotInterpreter) {
          this.$store.dispatch('AssignmentFlowStore/getBuyerInvoicePreview', {
            jobId: this.$route.params.id
          });
        }
      },
      invoiceGenerationInProgress() {
        if (this.invoiceGenerationInProgress && (this.isJobCompleted || this.showedUp)) {
          setTimeout(() => {
            this.$store.commit('InfoModalStore/setInfoModal', {
              text: this.$gettext('Final invoice generation is in progress. Please load this page again in 5 minutes.')
            });
          });
        }
      }
    },
    methods: {
      fetchSuitableInterpreters() {
        this.$refs.fai.fetchInterpreters();
      },
      openUnsuitableJobModal() {
        this.$store.commit('ModalStore/setModal', {
          component: 'unsuitable-job-modal',
          classes: ['unsuitable-modal'],
          data: {
            title: this.$gettext('This assignment is no longer available')
          },
          disableClose: true,
          width: 410
        });
      },
      getJobById() {
        const jobID = this.$route.params.id;
        if (!this.$store.state.OneAssignmentStore.storeJobObj || this.$store.state.OneAssignmentStore.storeJobObj.id != jobID) {
          this.$store.commit('OneAssignmentStore/startAssignmentProgress');
          this.$store.dispatch('OneAssignmentStore/getJobById', jobID).then(() => {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          }).catch(() => {
            this.$store.commit('OneAssignmentStore/stopAssignmentProgress');
          });
        }
        this.$store.dispatch('OneAssignmentStore/preselectPricingTemplate');
      }
    },
    mounted() {
      this.getJobById();
      if (this.dataPromise) {
        this.assignmentInfoProgress = true;
        this.dataPromise.then(() => {
          this.assignmentInfoProgress = false;
          if (this.isUnsuitableJob) this.openUnsuitableJobModal();
        });
      }
    },
    beforeDestroy() {
      this.$store.commit('OneAssignmentStore/clearAssignmentStore');
      this.$store.commit('AssignmentFlowStore/clearFlowData');
    },
    beforeRouteLeave(to, from, next) {
      this.$store.dispatch('PostInterpretationStore/clearStore');
      this.$store.commit('FeedbackStore/clearStore');
      this.$store.commit('s_DashboardStore/clearFeedbackJobs');
      this.$store.commit('PricingTemplatesStore/clearStore');
      this.$store.commit('FAIListStore/clearStore');
      this.$store.commit('FAIFiltersStore/clearStore');
      next();
    }
  };
</script>

<style src="@assets/css/default_accordion.css"></style>

<style scoped>
  .one-assignment {
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: 100%;
    margin: 0 auto;
    padding: 40px 20px 20px;
  }

  .one-assignment__main-wrapper {
    display: flex;
    align-items: flex-start;
  }

  .one-assignment__action-buttons {
    width: 100%;
    margin-bottom: 15px;
  }

  .one-assignment__info-cont {
    display: block;
    overflow: auto;
    width: 300px;
    min-width: 300px;
  }

  .one-assignment__info-cont--right {
    padding-right: 10px;
  }

  .one-assignment__info-cont--left {
    padding-left: 10px;
  }

  .one-assignment__interactive-area-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;
  }

  .one-assignment__interactive-area {
    width: 606px;
    max-width: 100%;
  }

  .one-assignment--full-width .one-assignment__interactive-area {
    width: 100%;
  }

  .one-assignment__confirmation-area {
    max-width: 1230px;
    margin-top: 10px;
  }

  .one-assignment__chat-content {
    display: flex;
    flex-grow: 1;
    height: 30%;
  }

  .one-assignment__chat-accordion {
    display: flex;
    flex-direction: column;
    height: 30%;
  }

  .one-assignment__replacement-accordion {
    display: flex;
    flex-direction: column;
    height: 30%;
    margin-bottom: 10px;
  }

  .one-assignment__inner-section {
    margin-bottom: 10px;
  }

  .one-assignment__admin-chat-content {
    height: 500px;
  }

  .one-assignment__chat-content.one-assignment__chat-wrapper--thin {
    height: 30%;
  }

  @media (max-width: 1024px) {
    .one-assignment__chat-content.one-assignment__chat-wrapper--thin {
      height: 20%;
    }

    .one-assignment__interactive-info-wrapper--travel-rates {
      overflow: auto;
    }
  }

  @media (max-width: 767px) {
    .one-assignment {
      padding: 10px 0 60px;
    }

    .one-assignment__main-wrapper {
      display: block;
      height: initial;
    }

    .one-assignment__interactive-area {
      width: 100%;
    }

    .one-assignment__action-buttons {
      padding: 0 15px;
    }

    .one-assignment__chat-content {
      min-height: initial;
    }

    .one-assignment__interactive-info-wrapper {
      max-height: initial;
      margin-top: 10px;
      margin-bottom: 10px !important;
      padding: 0;
    }

    .one-assignment__info-cont {
      overflow: initial;
      width: 100%;
      padding-right: 0;
      padding-left: 0;
    }

    .one-assignment__info-cont--left {
      margin-top: 10px;
    }

    .one-assignment__interactive-area-wrapper {
      width: 100%;
      height: initial;
    }

    .one-assignment--blocked {
      background-color: white;
    }

    .one-assignment--blocked .one-assignment__interactive-info-wrapper {
      padding: 0;
    }
  }
</style>
