// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/interpretation_account_manager_salita.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.sk-link[data-v-6c63963c],\n.sk-link--default[data-v-6c63963c] {\n  font-weight: bold;\n  font-size: 13px;\n}\n.manager-section__wrapper[data-v-6c63963c] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  padding: 10px;\n  border-radius: 3px;\n  background-color: #f4f5f7;\n}\n.manager-section__title[data-v-6c63963c] {\n  width: 100%;\n  padding-bottom: 5px;\n  color: #333;\n  font-weight: bold;\n  font-size: 14px;\n  text-align: center;\n}\n.manager-section__contacts[data-v-6c63963c] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  padding-left: 10px;\n}\n.one-assignment .tt-avatar.manager-section__avatar[data-v-6c63963c] {\n  width: 51px;\n  height: 51px;\n}\n.one-assignment .tt-avatar.manager-section__avatar--salita[data-v-6c63963c] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
